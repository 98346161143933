import './style.scss';
import './testimonials.scss';
import '../utils/google_analytics';
import '../utils/preloader';
import '../utils';
import RecaptchaHelper from '../utils/recaptcha_helper';

window.recaptcha_on_success = RecaptchaHelper.on_success;

document.addEventListener('turbolinks:load', () => {
  const contact_us_btns = document.querySelectorAll('a.contact_us'),
    $contact_us_section = document.getElementById('contact_us');

  contact_us_btns.forEach((btn) => {
    btn.addEventListener("click", (e) => {
      const subject = e.target.dataset.contactSubject;

      e.preventDefault();
      $contact_us_section.scrollIntoView();
      $contact_us_section.querySelector("#sales_contact_subject").value = subject;
    });
  });
});
