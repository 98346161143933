function fadeOutEffect(elem_id, step_interval_ms) {
  var fadeTarget = document.getElementById(elem_id);
  var fadeEffect = setInterval(function () {
    if (!fadeTarget.style.opacity) {
      fadeTarget.style.opacity = 1;
    }
    if (fadeTarget.style.opacity > 0) {
      fadeTarget.style.opacity -= 0.2;
    } else {
      clearInterval(fadeEffect);
      if( fadeTarget.parentNode ) {
        fadeTarget.parentNode.removeChild(fadeTarget);
      }
    }
  }, step_interval_ms || 100);
}


document.addEventListener("turbolinks:load", function() {
  fadeOutEffect("loading", 50);
});
document.addEventListener("DOMContentLoaded", function() {
  fadeOutEffect("loading", 50);
});
