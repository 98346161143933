import onClickOrTap from './on_click_or_tap';

const Navigation = {

  init: function(){
    document.querySelectorAll("nav.navbar .attr-nav").forEach(function($el){
      $el.querySelectorAll("li.side-menu > a").forEach(function($link){
        onClickOrTap($link, function(e){
          e.preventDefault();
          document.querySelector("nav.navbar > .side").classList.toggle("on");
          document.body.classList.toggle("on-side");
        });
      });
    });

    document.querySelectorAll(".side .close-side").forEach(function($el){
      onClickOrTap($el, function(e){
        e.preventDefault();
        document.querySelector("nav.navbar > .side").classList.toggle("on");
        document.body.classList.toggle("on-side");
      });
    });
  }
};

export default Navigation;
