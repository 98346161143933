export default function(){
  const $nav_links = document.querySelectorAll("#full-menu .nav-link"),
  path = window.location.pathname,
  hash = window.location.hash;

  $nav_links.forEach(function($link){
    let section = $link.dataset.section;
    switch(section){
      case "blog":
        if(path.match(/\/blog\//)){
          $link.classList.add("active");
        }
        break;
      case "contact_us":
      case "about":
        if(path.match(/^\/$/) && hash && hash.substr(1)===section){
          $link.classList.add("active");
        }
        break;
      case "home":
        if(path.match(/^\/$/) && (!hash || hash.substr(1)==="home")){
          $link.classList.add("active");
        }
        break;
    }
  });
}
